import { APIEndPoints } from '../common/endPoint';
import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'packages/mybridgev4/store/common/api';
import axios from 'packages/mybridgev4/store/common/axiosApi';

export const deleteTestimonial = createAsyncThunk(
  'deleteTestimonial',
  async (id) => {
    const obj = {
      url: `${APIEndPoints.Testimonial}${id}/`,
      method: 'DELETE',
    };
    return await API(obj);
  }
);

export const patchTestimonial = createAsyncThunk(
  'patchTestimonial',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Testimonial}${body.id}/`,
      method: 'PATCH',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const listOfReceivedTestimonial = createAsyncThunk(
  'listOfReceivedTestimonial',
  async ({ pageParam = 1 }) => {
    const obj = {
      url: `${APIEndPoints.Testimonial}list_of_received_testimonial/?page=${pageParam}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  }
);

export const listOfReceivedTestimonialPending = createAsyncThunk(
  'listOfReceivedTestimonialPending',
  async ({ pageParam = 1 }) => {
    const obj = {
      url: `${APIEndPoints.Testimonial}list_of_received_testimonial_pending_request/?page=${pageParam}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  }
);

export const listOfAskedTestimonialRequest = createAsyncThunk(
  'listOfAskedTestimonialRequest',
  async ({ pageParam = 1 }) => {
    const obj = {
      url: `${APIEndPoints.Testimonial}list_of_asked_testimonial_request/?page=${pageParam}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  }
);

export const listOfGiveTestimonialPendingRequest = createAsyncThunk(
  'listOfGiveTestimonialPendingRequest',
  async ({ pageParam = 1 }) => {
    const obj = {
      url: `${APIEndPoints.Testimonial}list_of_given_testimonial_request/?page=${pageParam}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  }
);

export const listOfGiveTestimonial = createAsyncThunk(
  'listOfGiveTestimonial',
  async ({ pageParam = 1 }) => {
    const obj = {
      url: `${APIEndPoints.Testimonial}list_of_given_testimonial/?page=${pageParam}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  }
);

export const addToProfile = createAsyncThunk('addToProfile', async (body) => {
  const obj = {
    url: `${APIEndPoints.Testimonial}${body.id}/`,
    method: 'PATCH',
    body: JSON.stringify(body),
  };
  const response = await API(obj);
  return response;
});

export const removeFromProfile = createAsyncThunk('removeFromProfile', async (body) => {
  const obj = {
    url: `${APIEndPoints.Testimonial}${body.id}/`,
    method: 'PATCH',
    body: JSON.stringify(body),
  };
  const response = await API(obj);
  return response;
});

export const remindForTestimonial = createAsyncThunk(
  'remindForTestimonial',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Testimonial}${body.id}/remind`,
      method: 'PUT',
    };
    const response = await API(obj);
    return response;
  }
);

export const giveTestimonalForAsked = createAsyncThunk(
  'giveTestimonalForAsked',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Testimonial}${body.id}/`,
      method: 'PATCH',
      body: JSON.stringify(body),
    };
    const response = await API(obj);
    return response;
  }
);

export const giveTestimonal = createAsyncThunk(
  'giveTestimonal',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Testimonial}`,
      method: 'POST',
      body: JSON.stringify(body),
    };
    const response = await API(obj);
    return response;
  }
);

export const askTestimonal = createAsyncThunk('askTestimonal', async (body) => {
  const obj = {
    url: `${APIEndPoints.Testimonial}`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  const response = await API(obj);
  return response;
});

export const endoseTestimonal = createAsyncThunk(
  'endoseTestimonal',
  async (id) => {
    const obj = {
      url: `${APIEndPoints.Testimonial}${id}/endorse`,
      method: 'POST',
    };
    const response = await API(obj);
    return response;
  }
);
